<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加配件</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <div class="flex gap-4">
          <en-scrollbar :height="height" view-class="w-60 bg-gray-100 p-4 flex flex-col gap-4">
            <div
              v-for="item of category.data"
              @click="category.item.click(item)"
              :class="['rounded-1 cursor-pointer text-sm p-2', { 'bg-primary text-white': category.current?.id === item.id }]"
            >
              <span>{{ item.name }}</span>
            </div>
          </en-scrollbar>

          <div class="flex-1 overflow-auto">
            <table-dynamic
              :height="height"
              code="GOODSFD"
              :data="table.data"
              :loading="table.loading"
              :paging="table.paging"
              :method="table.get"
              pagination
              :config="table.config"
            >
              <template #OPERATION="{ row }: { row: EnocloudCommonDefinitions['GoodsCategoryDto'] }">
                <button-delete :method="table.operation.delete.click" :params="row">删除</button-delete>
                <en-button @click="table.operation.price.click" :params="row" text>价格</en-button>
              </template>
              <template #NAME="{ row }: { row: EnocloudCommonDefinitions['GoodsCategoryDto'] }">
                <en-button type="primary" link @click="table.operation.name.click(row)">{{ row.name }}</en-button>
              </template>
            </table-dynamic>
          </div>
        </div>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑配件' : '添加配件'" direction="btt" size="80%">
    <en-form
      :model="detail.form.data"
      :rules="detail.form.rules"
      :loading="detail.form.loading"
      :ref="setRef('detailForm')"
      class="grid grid-cols-4 gap-6"
    >
      <en-form-item label="配件名称" prop="name">
        <en-input v-model="detail.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="配件类别" prop="category">
        <select-maintain
          v-model="detail.form.data.category"
          :ajax="{
            action: 'GET /enocloud/common/goods/category',
            params: (params, value) => (params.payload = { name: value })
          }"
          :props="{ label: 'name', value: 'code' }"
          value-key="id"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="基本单位" prop="warehouseUnit">
        <select-maintain
          v-model="detail.form.data.warehouseUnit"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['GDSUNT'])
          }"
          :props="{ label: 'name', value: 'name' }"
          value-key="id"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="品质类型" prop="type">
        <select-maintain
          v-model="detail.form.data.type"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['GOODSTP'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="OE号" prop="oe">
        <en-input v-model="detail.form.data.oe"></en-input>
      </en-form-item>
      <en-form-item label="配件助记符" prop="nameHint">
        <en-input v-model="detail.form.data.nameHint"></en-input>
      </en-form-item>
      <en-form-item label="编码" prop="serialNo">
        <en-input v-model="detail.form.data.serialNo"></en-input>
      </en-form-item>

      <en-form-item label="配件保质期" prop="shelfLifeMonths">
        <en-input v-model="detail.form.data.shelfLifeMonths"> <template #append>月</template></en-input>
      </en-form-item>
      <en-form-item label="安全库存下限" prop="upperBoundary">
        <en-input v-model="detail.form.data.upperBoundary"></en-input>
      </en-form-item>
      <en-form-item label="安全库存上限" prop="lowerBoundary">
        <en-input v-model="detail.form.data.lowerBoundary"></en-input>
      </en-form-item>
      <en-form-item label="固定维修价格">
        <en-input-number v-model="detail.form.data.servicePrice" class="w-full"></en-input-number>
      </en-form-item>

      <en-form-item label="配置统一价格">
        <select-maintain
          v-model="detail.form.data.uniqueServicePrice"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['FLAG'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>

      <en-form-item label="配件品牌">
        <select-maintain
          v-model="detail.form.data.brand"
          :ajax="{
            action: 'GET /enocloud/common/hint/:hintTypeStr',
            params: (params) => (params.paths = ['GDBRD'])
          }"
          :props="{ label: 'name', value: 'name' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="配件型号">
        <en-input v-model="detail.form.data.model"></en-input>
      </en-form-item>
      <en-form-item label="配件条码">
        <select-maintain
          v-model="detail.form.data.barcodes"
          :options="[]"
          multiple
          default-first-option
          allow-create
          clearable
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="产地">
        <en-input v-model="detail.form.data.placeOfProduction"></en-input>
      </en-form-item>
      <en-form-item label="配件状态">
        <select-maintain
          v-model="detail.form.data.status"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['GODSTAT'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>

      <en-form-item label="备注">
        <en-input v-model="detail.form.data.comment" maxlength="200" type="textarea" show-word-limit></en-input>
      </en-form-item>
      <en-form-item label="添加人员">
        <en-input v-model="detail.form.data.preparedByName" class="w-full" disabled></en-input>
      </en-form-item>
      <en-form-item label="添加时间" prop="preparedDatetime">
        <en-date-picker v-model="detail.form.data.preparedDatetime" class="w-full" disabled></en-date-picker>
      </en-form-item>
      <en-form-item label="配件图片">
        <upload-maintain v-model="detail.form.data.imgUrls" multiple :limit="10" folder="goods"></upload-maintain>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
  <en-drawer v-model="price.visible" :title="'编辑配件价格'" size="80%" direction="btt">
    <flex-box>
      <template #default="{ height }">
        <en-tabs v-model="tabs.active">
          <en-tab-pane label="按规格编辑价格" name="pricedetail">
            <en-table
              :data="price.form.data.specifications"
              :height="height"
              :loading="warehouse.table.loading"
              :method="warehouse.table.get"
              pagination
              :paging="warehouse.table.paging"
            >
              <en-table-column label="规格" prop="name"></en-table-column>
              <en-table-column label="换算比例" prop="weight"></en-table-column>
              <en-table-column label="维修价格" prop="servicePrice" v-if="store.user.accessRights.find((item) => item === 'PRICE_SERVICE')">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
                  <en-input v-model="row.servicePrice"></en-input>
                </template>
              </en-table-column>
              <en-table-column label="进价一" prop="purchasePrice1" v-if="store.user.accessRights.find((item) => item === 'PRICE_PURCHASE')">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
                  <en-input v-model="row.purchasePrice1"></en-input>
                </template>
              </en-table-column>
              <en-table-column label="进价二" prop="purchasePrice2" v-if="store.user.accessRights.find((item) => item === 'PRICE_PURCHASE')">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
                  <en-input v-model="row.purchasePrice2"></en-input>
                </template>
              </en-table-column>
              <en-table-column label="进价三" prop="purchasePrice3" v-if="store.user.accessRights.find((item) => item === 'PRICE_PURCHASE')">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
                  <en-input v-model="row.purchasePrice3"></en-input>
                </template>
              </en-table-column>
              <en-table-column label="销价一" prop="salePrice1" v-if="store.user.accessRights.find((item) => item === 'PRICE_SALE')">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
                  <en-input v-model="row.salePrice1"></en-input>
                </template>
              </en-table-column>
              <en-table-column label="销价二" prop="salePrice2" v-if="store.user.accessRights.find((item) => item === 'PRICE_SALE')">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
                  <en-input v-model="row.salePrice2"></en-input>
                </template>
              </en-table-column>
              <en-table-column label="销价三" prop="salePrice3" v-if="store.user.accessRights.find((item) => item === 'PRICE_SALE')">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
                  <en-input v-model="row.salePrice3"></en-input>
                </template>
              </en-table-column>

              <en-table-column label="最低售价" prop="salePriceLowest" v-if="store.user.accessRights.find((item) => item === 'PRICE_SALE')">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
                  <en-input v-model="row.salePriceLowest"></en-input>
                </template>
              </en-table-column>
              <en-table-column label="默认维修规格" prop="defaultService" v-if="store.user.accessRights.find((item) => item === 'PRICE_SERVICE')">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
                  <!-- event -->
                  <en-radio @input="price.table.service.click(row)" label="Y" :model-value="row.defaultStockTaking?.code">是</en-radio>
                </template>
              </en-table-column>
              <en-table-column label="默认采购规格" prop="defaultPurchase" v-if="store.user.accessRights.find((item) => item === 'PRICE_PURCHASE')">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
                  <en-radio @input="price.table.purchase.click(row)" label="Y" :model-value="row.defaultPurchase?.code">是</en-radio>
                </template>
              </en-table-column>
              <en-table-column label="默认销售规格" v-if="store.user.accessRights.find((item) => item === 'PRICE_SALE')">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
                  <en-radio @input="price.table.sale.click(row)" label="Y" :model-value="row.defaultSale?.code">是</en-radio>
                </template>
              </en-table-column>
              <en-table-column label="默认盘点规格">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
                  <en-radio @input="price.table.taking.click(row)" label="Y" :model-value="row.defaultStockTaking?.code">是</en-radio>
                </template>
              </en-table-column>
              <en-table-column label="默认移库规格">
                <template #default="{ row }: { row: EnocloudCommonDefinitions['GoodsSpecificationDto'] }">
                  <en-radio @input="price.table.transfer.click(row)" label="Y" :model-value="row.defaultStockTransfer?.code">是</en-radio>
                </template>
              </en-table-column>
            </en-table>
          </en-tab-pane>
          <en-tab-pane label="按批次编辑价格">
            <!-- <en-table
              :data="price.table.data"
              :height="height"
              :loading="warehouse.table.loading"
              :method="warehouse.table.get"
              pagination
              :paging="warehouse.table.paging"
            >
              <en-table-column label="批次" prop="batchNo"></en-table-column>
            </en-table> -->
          </en-tab-pane>
        </en-tabs>
      </template>
    </flex-box>
    <template #footer>
      <en-button @click="price.footer.cancel.click">取消</en-button>
      <button-ajax :method="price.footer.confirm.click"> 确定 </button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        },
        price: {
          async click(row: EnocloudCommonDefinitions['GoodsCategoryDto']) {
            this.price.form.data.id = row.id
            await this.price.form.get()
            this.price.visible = true
          }
        }
      },
      tabs: {
        active: 'pricedetail'
      },

      category: {
        current: null as EnocloudCommonDefinitions['GoodsCategoryDto'] | null,
        ajax: {
          get: {
            action: 'GET /enocloud/common/goods/category',
            data: 'array'
          }
        },
        children: {
          item: {
            click(row: EnocloudCommonDefinitions['GoodsCategoryDto']) {
              this.category.current = row
              this.table.getByTree({ paths: [row.id] })
            }
          }
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/goods',
            data: 'array',
            pagination: true,
            loading: true
          },
          getByTree: {
            action: 'GET /enocloud/common/goods/category/:categoryId/goods',
            data: 'array',
            pagination: true,
            loading: true
          },
          delete: {
            action: 'DELETE /enocloud/common/goods/:goodsId',
            loading: true
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudCommonDefinitions['GoodsDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            },
            price: {
              async click(row: EnocloudCommonDefinitions['GoodsDto']) {
                this.price.visible = true
              }
            },
            name: {
              click(row: EnocloudCommonDefinitions['GoodsDto']) {
                this.detail.form.init()
                this.detail.form.data.id = row.id
                this.detail.form.get()
                this.detail.visible = true
              }
            }
          }
        },
        config: {
          NAME: { header: { filter: { type: 'text', field: 'name' } } },
          // COMMENT: { header: { filter: { type: 'text', field: 'comment' } } },
          PLACE_OF_PRODUCTION: { header: { filter: { type: 'text', field: 'placeOfProduction' } } },
          BARCODE: { header: { filter: { type: 'text', field: 'barcode' } } },
          MODEL: { header: { filter: { type: 'text', field: 'model' } } },
          BRAND: { header: { filter: { type: 'text', field: 'oem' } } },
          SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } },
          OEM: {
            header: {
              filter: {
                type: 'text',
                field: 'oem'
              }
            }
          },
          PRIMARY_VEHICLE_SPEC: {
            header: {
              filter: {
                type: 'text',
                field: 'primaryVehicleSpec'
              }
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          vehicle: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/vehicle/type',
                data: 'array'
              }
            }
          },
          form: {
            is: 'form',
            data: { imgUrls: [] },
            ajax: {
              get: {
                action: 'GET /enocloud/common/goods/:goodsId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },

              submit: {
                action: 'POST /enocloud/common/goods',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/goods',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              type: [{ required: true, message: '请选择品质类别' }],
              name: [{ required: true, message: '请输入项目名称' }],
              category: [{ required: true, message: '请输入项目类别' }],
              warehouseUnit: [{ required: true, message: '请输入基本单位' }]
            }
          },

          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      },

      price: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              goodsId: ''
            },
            ajax: {
              get: {
                action: 'GET /enocloud/common/goods/:goodsId',
                data: 'object',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = [this.price.form.data.id]
                }
              },
              submit: {
                action: 'PUT /enocloud/common/goods/specification/addition',
                params(params) {
                  params.payload = this.price.form.data
                }
              }
            }
          },
          table: {
            service: {
              click(row: EnocloudCommonDefinitions['GoodsSpecificationDto']) {
                this.price.table.additionFormDataSpecificationsDefaultInputCommon(row, 'defaultService')
              }
            },
            purchase: {
              click(row: EnocloudCommonDefinitions['GoodsSpecificationDto']) {
                this.price.table.additionFormDataSpecificationsDefaultInputCommon(row, 'defaultPurchase')
              }
            },
            sale: {
              click(row: EnocloudCommonDefinitions['GoodsSpecificationDto']) {
                this.price.table.additionFormDataSpecificationsDefaultInputCommon(row, 'defaultSale')
              }
            },
            taking: {
              click(row: EnocloudCommonDefinitions['GoodsSpecificationDto']) {
                this.price.table.additionFormDataSpecificationsDefaultInputCommon(row, 'defaultStockTaking')
              }
            },
            transfer: {
              click(row: EnocloudCommonDefinitions['GoodsSpecificationDto']) {
                this.price.table.additionFormDataSpecificationsDefaultInputCommon(row, 'defaultStockTransfer')
              }
            },

            additionFormDataSpecificationsDefaultInputCommon(row: EnocloudCommonDefinitions['GoodsSpecificationDto'], type: any) {
              this.price.form.data.specifications?.forEach((item: EnocloudCommonDefinitions['GoodsSpecificationDto']) => {
                item[type] = {
                  code: 'N'
                }
              })
              row[type] = {
                code: 'Y'
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.price.visible = false
              }
            },
            confirm: {
              async click() {
                await this.price.form.submit()
                return this.table.get().then(() => (this.price.visible = false))
              }
            }
          }
        }
      },
      warehouse: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              barcode: '',
              brand: '',
              category: {
                id: '',
                name: ''
              },
              categoryIds: [],
              comment: '',
              figureNo: '',
              importType: {
                code: 'Y',
                type: '',
                message: ''
              },
              inventoryConfigs: [],
              inventoryCount: '',
              model: '',
              name: '',
              nameHint: '',
              placeOfProduction: '',
              preparedBy: {
                id: '',
                name: ''
              },
              preparedDatetime: '',
              primaryVehicleSpec: '',
              secondaryVehicleSpecs: [],
              serialNo: '',
              specifications: [],
              status: {
                code: '',
                type: '',
                message: ''
              },
              tags: [],
              warehouseUnit: '',
              imgUrls: []
            },
            ajax: {
              get: {
                action: 'GET /enocloud/common/goods/:goodsId',
                data: 'object',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = [this.warehouse.form.data.id]
                }
              },
              submit: {
                action: 'PUT /enocloud/common/goods/inventory/config',
                params(params) {
                  params.payload = this.warehouse.form.data
                }
              }
            },
            computed: {
              formData() {
                if (!this.warehouse.form) return this.warehouse.form.data
                this.warehouse.form.data.inventoryConfigs.forEach((item: any) => {
                  const target = this.warehouse.table.tableData.find((inner: any) => inner.warehouse.id === item.warehouse.id)
                  if (target) {
                    Object.assign(target, item)
                  }
                })
                return this.warehouse.form.data
              }
            }
          },
          table: {
            ajax: {
              get: {
                action: 'GET /enocloud/common/warehouse',
                data: 'array',
                loading: true,
                pagination: true
              }
            },
            computed: {
              tableData() {
                this.warehouse.table.data = this.warehouse.table.data.map((item: any) => {
                  const res = {
                    alarmLine: 0,
                    comment: '',
                    lowerLimit: 0,
                    upperLimit: 0,
                    warehouseShelf: '',
                    warehouse: {}
                  }
                  res.warehouse = item
                  return res
                })
                return this.warehouse.table.data
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.warehouse.visible = false
              }
            },
            confirm: {
              async click() {
                this.warehouse.form.data.inventoryConfigs = this.warehouse.table.tableData
                await this.warehouse.form.submit()
                return this.table.get().then(() => (this.warehouse.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.category.get()
    this.table.get()
    this.detail.vehicle.get()

    this.price.form.get()
  }
})
</script>
